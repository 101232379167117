



























































































































































import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { MetaInfo } from 'vue-meta'
import { TiptapVuetify, Bold, Paragraph, Link } from 'tiptap-vuetify'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PeopleMixin from '@/mixins/PeopleMixin'
// components
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import Chip from '@/components/_uikit/Chip.vue'
import ControlButtons from '@/components/ControlButtons.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// interfaces
import { IProjectResponse, IProjectRequest } from '@/store/types/projects'
import { IChip, IPersonWithSelect } from '@/store/types'
// store
import projectsModule from '@/store/modules/projects'
import commonStore from '@/store/modules/common'
import ConfirmationMixin from '@/mixins/ConfirmationMixin'

@Component({
  components: {
    ButtonWithPlus,
    TextAreaInput,
    TextInput,
    FileUploader,
    Select,
    ValidationObserver,
    ValidationProvider,
    Chip,
    ControlButtons,
    Confirmation,
    TiptapVuetify,
  },
})
export default class ProjectsItemForm extends mixins(NotifyMixin, PeopleMixin, ConfirmationMixin) {

  private extensions = [
    Bold,
    Paragraph,
    Link,
  ]

  private isSubmitting = false

  private get isAddMode() {
    return this.$route.name === 'projects.add'
  }

  private projectName = ''

  private metaInfo (): MetaInfo {
    return {
      title: 'Проекты',
      titleTemplate: this.isAddMode ? 'Добавление проекта' : `%s - ${this.projectName}`,
    }
  }

  private get selectedPeoples(): number[] {
    if (this.form && this.form.coordinatorId && !this.form.chairmanId) {
      return [this.form.coordinatorId, ...this.addedPeopleChips.map(person => person.id)]
    }
    if (this.form && this.form.chairmanId && !this.form.coordinatorId) {
      return [this.form.chairmanId, ...this.addedPeopleChips.map(person => person.id)]
    }
    if (this.form && this.form.coordinatorId && this.form.chairmanId) {
      return [this.form.coordinatorId, this.form.chairmanId, ...this.addedPeopleChips.map(person => person.id)]
    }
    return [...this.addedPeopleChips.map(person => person.id)]
  }

  private get enablePeoples(): IPersonWithSelect[] {
    const arr = this.people.map(person => ({ ...person, disabled: false }))

    this.selectedPeoples.forEach(id => {
      arr.forEach((person: IPersonWithSelect, idx) => {
        if (id === person.id) {
          arr[idx].disabled = true
        }
      })
    })

    return arr
  }

  private isLoading = true

  private id = +this.$route.params.projectID

  private logoFileName: string | null = null
  private logoSliderFileName: string | null = null
  private passportFileName: string | null = null
  private addedPeopleChips: IChip[] = []

  private councilMemberId = -1

  private form: IProjectRequest | null = null

  private newProject() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Проекты', path: 'main' },
        { name: 'Добавление проекта', path: this.$route.path },
      ])
    }, 10)

    this.isLoading = false

    this.logoFileName = ''
    this.logoSliderFileName = ''

    this.form = {
      name: '',
      description: '',
      content: '',
      logoId: null,
      sliderLogoId: null,
      passportId: null,
      coordinatorId: null,
      chairmanId: null,
      councilMembers: [],
      published: false,
      information: [],
    }
  }

  private content = ''

  private updateProject() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Проекты', path: 'main' },
      ])
    }, 10)

    if (!projectsModule.project || this.id !== projectsModule.currentProjectId) {
      projectsModule.setCurrenProjectId(this.id)
      projectsModule.fetchProject()
        .then((response: IProjectResponse) => {
          this.form = this._transformData(response)
          this.logoSliderFileName = response.sliderLogo ? response.sliderLogo.originalName : ''
          this.logoFileName = response.logo ? response.logo.originalName : ''
          this.passportFileName = response.passport ? response.passport.originalName : ''
          this.addedPeopleChips = response.councilMembers.map(person => ({ id: person.id, name: `${person.surname} ${person.name} ${person.patronymic}` }))

          commonStore.setBreadcrumbs([
            { name: response.name, path: this.$route.path },
          ])
          this.projectName = response.name
        })
        .catch(err => this.notifyError(err))
        .finally(() => this.isLoading = false)
    } else {
      this.form = this._transformData(projectsModule.project)
      this.logoFileName = projectsModule.project.logo.originalName
      this.logoSliderFileName = projectsModule.project.sliderLogo.originalName
      this.passportFileName = projectsModule.project.passport.originalName
      this.addedPeopleChips = projectsModule.project.councilMembers.map(person => ({ id: person.id, name: `${person.surname} ${person.name} ${person.patronymic}` }))
      this.isLoading = false

      setTimeout(() => {
        commonStore.setBreadcrumbs([
          { name: projectsModule.project ? projectsModule.project.name : '', path: this.$route.path },
        ])
      }, 10)
      this.projectName = projectsModule.project.name
    }
  }

  private mounted() {
    this.isAddMode ? this.newProject() : this.updateProject()
    this.content = this.form && this.form.content ? this.form.content : ''
  }

  private addProject() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form) {
            projectsModule.createProject(this.form)
              .then(() => {
                this.$router.push({ name: 'projects.main' })
                this.notifySuccess('Новый проект успешно создан')
              })
              .catch((err) => this.notifyError(err))
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private changeProject() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form) {
            projectsModule.updateProject(this.form)
              .then(() => {
                this.$router.push({ name: 'projects.main' })
                this.notifySuccess('Данные обновлены')
              })
              .catch((err) => this.notifyError(err))
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private deleteProject() {
    projectsModule.deleteProject()
      .then(() => {
        this.$router.push({ name: 'projects.main' })
        this.notifySuccess('Проект успешно удалён')
      })
      .catch((err) => {
        this.notifyError(err)
      })
  }

  private addLabel() {
    if (this.form) {
      this.form.information.push({
        caption: '',
        number: null,
      })
    }
  }

  private removeLabel(index: number) {
    if (this.form) {
      this.form.information = this.form.information.filter((label, idx) => idx !== index)
    }
  }

  private _transformData(project: IProjectResponse): IProjectRequest {
    return {
      name: project.name,
      description: project.description,
      content: project.content,
      logoId: project.logo ? project.logo.id : null,
      sliderLogoId: project.sliderLogo ? project.sliderLogo.id : null,
      passportId: project.passport ? project.passport.id : null,
      coordinatorId: project.coordinator ? project.coordinator.id : null,
      chairmanId: project.chairman ? project.chairman.id : null,
      councilMembers: project.councilMembers.map(item => item.id),
      published: project.published,
      information: project.information ? project.information : [],
    }
  }

  private addToCouncilMembers() {
    const nameOfPerson = this.mapOfPeople.get(this.councilMemberId)
    // CHIPS
    if (nameOfPerson) {
      this.addedPeopleChips = [
        ...this.addedPeopleChips,
        { id: this.councilMemberId, name: nameOfPerson },
      ]
    }
    // FORM
    this.form && this.form.councilMembers.push(this.councilMemberId)
    this.councilMemberId = -1
  }

  private removeFromCouncilMembers(id: number) {
    this.addedPeopleChips = this.addedPeopleChips.filter((chip) => chip.id !== id)

    if (this.form) {
      this.form.councilMembers = this.form.councilMembers.filter(idx => idx !== id)
    }
  }

  private startLoad() {
    this.isSubmitting = true
  }

  private endLoad() {
    this.isSubmitting = false
  }

  @Watch('form.content')
  private formContentWatch(value) {
    if (value === '' || value === '<p></p>') {
      this.content = ''
    } else {
      this.content = value
    }
  }
}
