import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/utils/services/api'
import store from '@/store'
import { IBreadcrumbs, IFileData, IPerson, IPersonList, IPersonWithSelect } from '@/store/types/index'

@Module({
  dynamic: true,
  name: 'common',
  store,
})
class Common extends VuexModule {
  // ------------------------------ COMMON ------------------------------ >>

  breadcrumbs: IBreadcrumbs[] = []

  people: IPersonWithSelect[] = []

  @Mutation
  setBreadcrumbs(payload: IBreadcrumbs[]) {
    this.breadcrumbs = [...this.breadcrumbs, ...payload]
  }

  @Mutation
  clearBreadcrumbs() {
    this.breadcrumbs = []
  }

  @Mutation
  setPeople(payload: IPersonWithSelect[]) {
    this.people = payload
  }

  @Action({ rawError: true })
  async fetchFile(payload: File): Promise<IFileData> {
    const fd: FormData = new FormData()
    fd.set('file', payload)

    const fileType = payload.name.split('.').pop()?.toLowerCase()
    const imageTypes = ['svg', 'png']
    
    const type = imageTypes.includes(fileType || '') ? 'image' : 'file'
    fd.set('type', type)

    const { data } = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_ENDPOINT}/files`,
      fd,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
        },
      },
    )

    return data
  }

  @Action({ rawError: true })
  async fetchFiles(payload: File[]): Promise<IFileData[]> {
    
    const files: FormData = new FormData()
    payload.forEach(item => {
      files.append('files[]', item)
    })

    const { data } = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_ENDPOINT}/files/many`,
      files,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
        },
      },
    )

    return data

  }

  @Action({ rawError: true })
  async getPeople(params: any): Promise<IPersonList> {
    const { data } = await api.get(
      '/people',
      { params },
    )

    const response: IPersonWithSelect[] = data.data.map((person: IPerson) => ({
      id: person.id,
      name: `${person.surname} ${person.name} ${person.patronymic}`,
    }))

    this.setPeople(response)

    return data
  }
}

const commonStore = getModule(Common)

export default commonStore
